import React from "react"
import ReactDOM from "react-dom"
import _ from "lodash"
import ProximityFeedback from "react-proximity-feedback"
import scrollToElement from "scroll-to-element"
import FeaturedContentSlide from "./FeaturedContentSlide"
import { isBrowser } from "../../utils/browser"
import Arrow from "../../svg/Arrow"
import Circle from "../../svg/Circle"

class FeaturedContentSlider extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      slider: null,
      slides: null,
      slideInTransition: false,
      autoplay: false,
      percent: 0,
      sliderIndex: 0,
      circleDuration: 6,
      previousProgress: 0,
      sliderDirection: `right`,
    }
    this.slider = null
    this.slides = []
    this.revealContent = this.revealContent.bind(this)
    this.debounce = _.debounce(this.updateState, 5)

  }

  componentDidMount() {
    const Flickity = isBrowser() ? require(`flickity`) : undefined
    // init slider
    this.slider = new Flickity(this.refs.slider, {
      cellAlign: `left`,
      draggable: true,
      wrapAround: true,
      percentPosition: false,
      contain: false,
      prevNextButtons: false,
      pageDots: false,
      selectedAttraction: 0.085,
      friction: 0.65,
    })
    
    // attach to component state
    this.setState({
      slider: this.slider,
      percent: this.sliderPercent(),
    })

    this.slider.on(`scroll`, progress => {
      this.debounce()
    })
  }

  updateState() {
    this.setState({
      percent: this.sliderPercent(),
      sliderIndex: this.slider.selectedIndex,
    })
  }

  revealContent = () => {
    scrollToElement(ReactDOM.findDOMNode(this.refs.slider), {
      duration: 1000,
      offset: 800,
      ease: `inOutQuad`,
    })
  }

  sliderPercent = () =>
    ((this.slider.selectedIndex + 1) * 100) / this.slider.slides.length

  proximityTransform = (proximity, index) => {
    if (this.slider && !this.state.slideInTransition) {
      const factor = -30
      let xTransform = proximity * factor
      let isActive = this.slider.selectedIndex !== index
      let isNext = this.slider.selectedIndex + 1 === index
      let isFirst = index === 0

      return {
        zIndex: (!isNext && !isFirst) || (isFirst && !isActive) ? null : 1000,
        transform:
          (!isNext && !isFirst) || (isFirst && !isActive)
            ? null
            : `translate3d(${xTransform}px,0,0)`,
      }
    } else {
      return {}
    }
  }

  featuredContentSlides = () => {
    // recreate array
    this.slides = []

    // filter in only ContentfulFeaturedContent type
    let filteredContent = this.props.featuredContent.content

    // create a slider for each slide
    filteredContent.map((featuredContent, index) =>
      this.slides.push(
        <ProximityFeedback key={index} throttleInMs={0.001} threshold={400}>
          {({ ref, proximity }) => (
            <article
              key={index}
              ref={ref}
              className={`featured-content__slide`}
              // style={this.proximityTransform(proximity, index)}
            >
              <FeaturedContentSlide
                key={index}
                proximity={proximity}
                index={index}
                parent={this}
                slider={this.state.slider}
                slides={this.slides}
                autoplay={this.state.autoplay}
                content={featuredContent}
              />
            </article>
          )}
        </ProximityFeedback>
      )
    )

    return this.slides
  }

  render() {
    return (
      <header ref="container" className={`featured-content-slider`}>
        <Circle percent={this.state.percent} size={80} />
        <a
          className="btn__arrow featured-content-slider__scroll_content"
          onClick={e => this.revealContent()}
          aria-hidden="true"
          role="button"
        >
          <Arrow direction="bottom" color="#ffffff" />
        </a>
        <div ref="slider">
          {this.featuredContentSlides()}
        </div>
      </header>
    )
  }
}

FeaturedContentSlider.defaultProps = {
  isVisible: true,
}

export default FeaturedContentSlider
