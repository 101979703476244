import React from "react"
import { graphql, navigate } from "gatsby"
import Slide from "react-reveal/Slide"
import ProximityFeedback from "react-proximity-feedback"

import { setTransitionType } from "../../actions"
import { connect } from "react-redux"

import { Player, LoadingSpinner } from "video-react"
import FigureBox, { boxFormats } from "../../components/FigureBox"

class FeaturedContentSlideTitle extends React.Component {
  constructor(props) {
    super(props)
  }

  titleAnimation(s = ``) {
    let words = s.toString().split(` `)
    return words.map((word, index) => (
      <span key={index} className="text_word__hidden_overflow">
        <Slide
          up
          when={this.props.parent.state.isPlaying}
          distance="200%"
          duration={750}
          delay={75 * index}
        >
          <span className="text_line__word">
            {word}
            &nbsp;
          </span>
        </Slide>
      </span>
    ))
  }

  render() {
    return (
      <ProximityFeedback throttleInMs={25} threshold={200}>
        {({ ref, proximity }) => (
          <div ref={ref}>
            <h6 className="office__shortname d-none d-sm-block pride-text">
              {/* {this.props.officeName &&
                this.titleAnimation(this.props.officeName)} */}
              {this.props.officeName}
            </h6>
            <h2 className="work__preview__title h1 pride-text">
              {/* {this.titleAnimation(this.props.title)} */}
              {this.props.title}
            </h2>
            <hr style={{ width: `${proximity * 100}%` }} />
          </div>
        )}
      </ProximityFeedback>
    )
  }
}

class FeaturedContentSlide extends React.Component {
  constructor(props) {
    super(props)
    this.el = null
    this.timer = null
    this.timerDuration = null
    this.state = {
      source: null,
      isPlaying: false,
      videoStarted: false,
    }
    this.goToSelf = this.goToSelf.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    // set video source when is active and with no active source
    if (!prevState.source && this.isSelected()) {
      this.setSource()

      // handle preloading of this slide, only when its next to the selected slide
    } else if (!prevState.source) {
      if (this.props.index - 1 === this.props.slider.selectedIndex) {
        this.setSource()
      }
    }

    // start or pause video
    if (!prevState.isPlaying && this.isPaused() && this.isSelected()) {
      // start video
      this.start()
    } else if (prevState.isPlaying && !this.isPaused() && !this.isSelected()) {
      // pause when not active and not selected
      this.pause()
    }
  }

  setSource() {
    this.setState({
      source: this.props.content.video.file.url,
    })

    // init observer on video
    this.initPlayerObserver()
  }

  initPlayerObserver() {
    if (this.refs.player) {
      this.refs.player.subscribeToStateChange(this.observer.bind(this))
    }
  }

  goToSelf() {
    if (!this.isSelected()) {
      this.props.slider.select(this.props.index)
    }
  }

  isSelected() {
    return this.props.index === this.props.parent.state.slider.selectedIndex
  }

  start() {
    // console.warn(this.props.index, 'starting');

    if (this.refs.player !== undefined) {
      // first rewing
      this.rewind()

      // start video
      this.refs.player.play()

      // forcing mute on video
      this.refs.player.video.video.setAttribute(`muted`, `1`)

      this.setState({
        isPlaying: true,
      })
    }
  }

  isPaused() {
    return this.refs.player.video.video.paused
  }

  pause() {
    if (this.refs.player !== undefined) {
      this.refs.player.pause()
      this.setState({
        isPlaying: false,
        videoStarted: false,
      })
    }
  }

  next() {
    if (this.refs.player !== undefined) {
      if (this.props.index === this.props.slider.slides.length - 1) {
        this.props.slider.select(0)
      } else {
        this.props.slider.select(this.props.index + 1)
      }
    }
  }

  rewind() {
    this.refs.player.seek(0)
  }

  observer(playerState, playerPrevState) {
    // if has started playing, state this
    if (playerState.currentTime > 0) {
      this.setState({
        videoStarted: true,
      })
    }

    // when selected
    if (this.isSelected()) {
      // go to next
      if (playerState.ended && this.props.autoplay) {
        this.pause()
        this.next()
      }
    }
  }

  transformSlide() {
    // const factor = this.props.sliderDirection == 'right' ? -0.575 : 0.575
    const factor = -0.375

    if (this.props.slider != null && this.props.index !== 0) {
      // find current slide equivalent to this compoment in props slider
      let slide = this.props.slider.slides[this.props.index]

      // calculate transformation
      let x = Math.max((slide.target + this.props.slider.x) * factor, 0)
      // let x = (slide.target + this.props.slider.x ) * factor

      // return transformed style
      return {
        transform: `translate3d(${x}px,0,0)`,
      }
    } else {
      return {}
    }
  }

  proximityTransform() {
    return {
      opacity: this.state.isPlaying ? 1 : Math.max(0.25, this.props.proximity),
    }
  }

  media() {
    if (this.props.content.video) {
      return (
        <div className="featured-content__media">
          <Player
            ref="player"
            controls={false}
            muted={true}
            preload={`auto`}
            playsInline
            fluid={true}
            src={this.state.source}
          >
            <LoadingSpinner className="is-hidden" />
          </Player>
          {/* visible only on mobile devices */}
          <div
            className={`
              video-react__video_poster
              d-block
              d-sm-none
              ${this.state.videoStarted ? `is-faded` : ``}
            `}
          >
            <FigureBox
              format={boxFormats.auto}
              backgroundSize={`auto 100%`}
              backgroundPosition={`center center`}
              preferWebP={false}
              source={this.props.content.mainImage}
            />
          </div>
        </div>
      )
    } else {
      return (
        <div className="featured-content__media">
          <FigureBox
            format={boxFormats.auto}
            source={this.props.content.mainImage}
          />
        </div>
      )
    }
  }

  handleClick(event) {
    event.preventDefault()
    // console.log("Work__ " + JSON.stringify(this.props.content))
    // console.log(this.props.content)
    if (this.props.content.work) {
      this.props.setTransitionType(`swipe`, 1200)
      navigate(`/work/${this.props.content.work.slug}`)
    } else if (this.props.content.news) {
      this.props.setTransitionType(`swipe`, 1200)
      let slug_url = this.props.content.isCulturePost ? `culture` : `news`
      // console.log(slug_url)
      navigate(`/${slug_url}/${this.props.content.news.slug}`)
    }
    else{
      window.open(this.props.content.externalLink, '_blank', 'noopener,noreferrer')
    }
  }

  render() {
    let LinkElement =
      this.props.content.work || this.props.content.news ? `a` : `div`
    return (
      <article
        ref={e => {
          this.el = e
        }}
        className="featured-content__slide__inner"
        style={this.proximityTransform()}
      >
        <a
          className="featured-content__slide__click_to_self_handle"
          onClick={e => this.goToSelf()}
          aria-hidden="true"
          role="button"
        >
          Click to reveal
        </a>
        <div style={this.transformSlide()}>{this.media()}</div>
        <header className="work__preview__infos">
          <LinkElement className="is-link" onClick={e => this.handleClick(e)}>
            <FeaturedContentSlideTitle
              parent={this}
              title={this.props.content.title}
              officeName={
                this.props.content.office
                  ? this.props.content.office.shortName
                  : null
              }
            />
          </LinkElement>
        </header>
      </article>
    )
  }
}

export default connect(
  null,
  {
    setTransitionType,
  }
)(FeaturedContentSlide)

//
// Each featuredContent fields
//
export const featuredContentFragment = graphql`
  fragment featuredContentFragment on ContentfulFeaturedContent {
    id
    title
    createdAt
    isCulturePost
    externalLink
    office {
      id
      officeName
      shortName
      slug
    }
    work {
      id
      slug
    }
    news {
      id
      slug
    }
    video {
      id
      file {
        url
        fileName
        contentType
      }
    }
    mainImage {
      id
      gatsbyImageData(width: 1800, height: 900, quality: 70)
    }
  }
`
