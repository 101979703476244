import React from "react"
import { Link, graphql } from "gatsby"

import Layout from '../components/layout'
import SEO from '../components/seo'

import FeaturedContentSlider from "../content/featuredContent/FeaturedContentSlider"
import ContentPreview, {
  ContentPreviewLayouts,
} from "../content/preview/ContentPreview"
import indexColumns from "../utils/indexColumns"
import Text from "../content/texts/Text"

import { invertHeader, resetHeader } from "../actions"
import { connect } from "react-redux"

class LandingPage extends React.Component {
  constructor(props) {
    super(props)

    this.maxColumn = 6

    // create class instance for column number, delay, etc
    this.indexColumns = new indexColumns(0, this.maxColumn)

  }

  componentDidMount() {
    this.props.invertHeader()
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 25)
  }

  componentWillUnmount() {
    this.props.resetHeader()
  }

  layout = index => {
    let className = []

    // 1st item
    if (this.indexColumns.columnNumber === 1) {
      className.push(`col-12 col-md-6`)
      className.push(ContentPreviewLayouts.compact_fill)
    }

    // first element compact element, set "compact_fill" layout
    if (this.indexColumns.columnNumber === 2) {
      className.push(`col-12 col-md-6`)
      className.push(ContentPreviewLayouts.comact)

      // 3rd is fullwith
    } else if (
      this.indexColumns.columnNumber === 3 ||
      this.indexColumns.columnNumber === this.maxColumn
    ) {
      className.push(`col-12`)
      className.push(ContentPreviewLayouts.full)

      // all others are only "compact"
    } else if (this.indexColumns.columnNumber === 4) {
      className.push(`col-12 col-md-6`)
      className.push(ContentPreviewLayouts.compact_offset_left)

      // all others are only "compact"
    } else if (
      this.indexColumns.columnNumber > 4 ||
      this.indexColumns.columnNumber < this.maxColumn
    ) {
      className.push(`col-12 col-md-6`)
      className.push(ContentPreviewLayouts.compact)
    }

    // and left/right gutters on some column numbers
    if (
      this.indexColumns.columnNumber === 1 ||
      this.indexColumns.columnNumber === 4
    ) {
      className.push(`right-gutter`)
    }
    if (
      this.indexColumns.columnNumber === 2 ||
      this.indexColumns.columnNumber === 5
    ) {
      className.push(`left-gutter`)
    }

    return className.join(` `)
  }

  listContent = () => {
    if (this.props.data.homeContent.content.length > 0) {
      // will hold content return
      let content = []

      // XXX: fixing hot reloader issue
      this.indexColumns.reset()

      // loop each content node
      this.props.data.homeContent.content.slice(0, 9).map((item, index) => {
        // set current column
        this.indexColumns.increment()

        let type
        switch (item.__typename) {
          case `ContentfulWork`:
            type = `work`
            break
          case `ContentfulNews`:
            if (item.displayOnWorking) {
              type = `culture`
            } else {
              type = `news`
            }
            break
        }

        // add all content
        content.push(
          <ContentPreview
            key={index}
            index={index}
            contentData={item}
            contentType={type}
            layout={this.layout()}
            visible="is-visible"
            effect={
              index < 2 ? `effects__slide_from_left` : `effects__fade_down`
            }
            delay={this.indexColumns.getDelay()}
            duration={index < 2 ? 1250 : 400}
          />
        )

        // insert quote block before third column, only on first column loop
        if (this.indexColumns.columnNumber === 2 && !this.indexColumns.looped) {
          content.push(
            <div key={new Date()} className="landing__blurb_text col-12">
              <Link to="/about/">
                <Text
                  text={this.props.data.homeBlurbText}
                  breakTextAnimation={false}
                  responsiveText={true}
                  textWrapperTag={`h2`}
                  contentClassName="col-12 col-md-10 col-lg-9 mx-auto pride-text"
                />
              </Link>
            </div>
          )
        }
      })

      return content
    }
  }
  
  render() {
    return (
      <Layout>
        <SEO 
        title={`Wieden+Kennedy - Integrated Advertising Campaigns`} 
        description={`Here's where we post our latest and greatest work from all our offices worldwide. Come see what we've been up to.`} 
        seoFields={this.props.data.seoFields}
        />

        <section className="landing-page">

          {/* top featured content slider */}
          <FeaturedContentSlider
            parent={this}
            featuredContent={this.props.data.featuredContentSlides}
          />

          {/* featured work and features news */}
          <div className={`row`}>{this.listContent()}</div>
        </section>
      </Layout>
    )
  }
}

const mapStateToProps = store => {
  return { SiteHeaderState: store.SiteHeaderState }
}

export default connect(
  mapStateToProps,
  {
    invertHeader,
    resetHeader,
  }
)(LandingPage)

export const query = graphql`
  query landing {
    homeBlurbText: contentfulTexts(slug: { eq: "home-blurb-text" }) {
      ...textFragment
    }

    seoFields: contentfulSeoFields(slug: { eq: "home-landing" }) {
      ...seoFieldsFragment
    }

    featuredContentSlides: contentfulCollections(slug: { eq: "home-slides" }) {
      slug
      content {
        ... on ContentfulFeaturedContent {
          ...featuredContentFragment
        }
      }
    }

    homeContent: contentfulCollections(slug: { eq: "home-content" }) {
      slug
      content {
        ... on ContentfulWork {
          ...workFragmentBase
        }
        ... on ContentfulNews {
          displayOnWorking
          ...newsFragmentBase
        }
      }
    }

    wiedensims: allContentfulWiedenism {
      edges {
        node {
          id
          wiedensim
        }
      }
    }
  }
`
