import React, { Component } from "react"

class Circle extends Component {
  style = () => {
    let r = this.props.size / 2 - 10
    let circumference = 2 * Math.PI * r
    let calculatedOffset =
      (circumference * this.props.percent) / 100 + circumference

    return {
      strokeDashoffset: calculatedOffset,
      strokeDasharray: circumference,
      strokeWidth: 2,
      stroke: this.props.color,
      fill: `transparent`,
      transformOrigin: `50% 50%`,
      transform: `rotate(270deg) scale(1, -1)`,
      transitionProperty: `stroke-dashoffset`,
      transitionDuration: `1s`,
    }
  }

  render() {
    return (
      <svg
        className={this.props.className}
        width={this.props.size}
        height={this.props.size}
        viewBox={`0 0 ${this.props.size} ${this.props.size}`}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className="outer"
          cx={this.props.size / 2}
          cy={this.props.size / 2}
          r={this.props.size / 2 - 10}
          style={this.style()}
        />
      </svg>
    )
  }
}

Circle.defaultProps = {
  size: 100,
  percent: 0,
  duration: 4,
  className: `icn__circle`,
  color: `#fff`,
}

export default Circle
